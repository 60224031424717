import { themeOptions as tOptions } from '@commonsku/styles';
import { isDate, parseDate as baseParseDate } from '../../utils';

export const isValid = (val) => val !== null && val !== undefined;
export const parseDate = (dt, def='') => isDate(dt) ? dt : (dt ? new Date(baseParseDate(dt)) : def);

export const themeOptions = {
    ...tOptions,
    colors: {
        ...tOptions.colors,
        // primary: '#ce979a',
        // primary0: '#e2c2c4',
        // cta: '#c7898a',
        // green: '#9da794',
        // special2: '#00d374',
        // secondary: tOptions.colors.cta,
        cloud_gray: '#dfe4ea',
        green: tOptions.colors.special2,
        yellow: tOptions.colors.special1,
        pink: tOptions.colors.special3,
        blue: '#2276A7',
        blue0: '#ECF8FF',
        blue5: '#2581b7ed',
        blue10: '#2276A7',
        blue100: '#0C5A88',
    },
};

export const order_types_order = {
    'INVOICE': 5,
    'SALES ORDER': 4,
    'ESTIMATE': 3,
    'PRESENTATION': 2,
    'OPPORTUNITY': 1,
};

export const order_types_status = {
    'INVOICE': {
        'Open': {
            value: 'Invoiced',
            color: themeOptions.colors.blue,
            order: 100,
            'img_src': '/images/portal/invoice.jpg',
        },
        'Sent to Client': {
            value: 'Invoiced',
            color: themeOptions.colors.blue,
            order: 100,
            'img_src': '/images/portal/invoice.jpg',
        },
        'Client Review': {
            value: 'Invoiced',
            color: themeOptions.colors.blue,
            order: 100,
            'img_src': '/images/portal/invoice.jpg',
        },
        'Exported': {
            value: 'Invoiced',
            color: themeOptions.colors.blue,
            order: 100,
            'img_src': '/images/portal/invoice.jpg',
        },
        'Paid': {
            value: 'Invoiced',
            color: themeOptions.colors.blue,
            order: 100,
            'img_src': '/images/portal/invoice.jpg',
        },
    },
    'SALES ORDER': {
        'New': {
            value: 'Sales Order',
            color: themeOptions.colors.pink,
            order: 10,
            'img_src': '/images/portal/salesorder.jpg',
        },
        'Pending Client Approval': {
            value: 'Sales Order',
            color: themeOptions.colors.pink,
            order: 11,
            'img_src': '/images/portal/salesorder.jpg',
        },
        'Client Change Requested': {
            value: 'Sales Order',
            color: themeOptions.colors.pink,
            order: 12,
            'img_src': '/images/portal/salesorder.jpg',
        },
        'Client Approved': {
            value: 'Sales Order',
            color: themeOptions.colors.pink,
            order: 13,
            'img_src': '/images/portal/salesorder.jpg',
        },
        'In Production': {
            value: 'Sales Order',
            color: themeOptions.colors.pink,
            order: 14,
            'img_src': '/images/portal/salesorder.jpg',
        },
        'Shipped': {
            value: 'Sales Order',
            color: themeOptions.colors.pink,
            order: 15,
            'img_src': '/images/portal/salesorder.jpg',
        },
        'Ready To Be Invoiced': {
            value: 'Sales Order',
            color: themeOptions.colors.pink,
            order: 16,
            'img_src': '/images/portal/salesorder.jpg',
        },
        'Invoiced': {
            value: 'Invoiced',
            color: themeOptions.colors.blue,
            order: 100,
            'img_src': '/images/portal/invoice.jpg',
        },
        'Closed': {
            value: 'Closed',
            color: themeOptions.colors.blue,
            order: 100,
            'img_src': '/images/portal/salesorder.jpg',
        },
    },
    'ESTIMATE': {
        'Open': {
            value: 'Estimate',
            color: themeOptions.colors.yellow,
            order: 19,
            'img_src': '/images/portal/estimate.jpg',
        },
        'Client Review': {
            value: 'Estimate',
            color: themeOptions.colors.yellow,
            order: 20,
            'img_src': '/images/portal/estimate.jpg',
        },
        'Converted': {
            value: 'Estimate',
            color: themeOptions.colors.yellow,
            order: 21,
            'img_src': '/images/portal/estimate.jpg',
        },
        'Closed': {
            value: 'Estimate',
            color: themeOptions.colors.yellow,
            order: 20,
            'img_src': '/images/portal/estimate.jpg',
        },
    },
    'PRESENTATION': {
        'Open': {
            value: 'Presentation',
            color: themeOptions.colors.yellow,
            order: 29,
            'img_src': '/images/portal/presentation.jpg',
        },
        'Client Review': {
            value: 'Presentation',
            color: themeOptions.colors.yellow,
            order: 30,
            'img_src': '/images/portal/presentation.jpg',
        },
        'Converted': {
            value: 'Presentation',
            color: themeOptions.colors.yellow,
            order: 31,
            'img_src': '/images/portal/presentation.jpg',
        },
        'Closed': {
            value: 'Presentation',
            color: themeOptions.colors.yellow,
            order: 30,
            'img_src': '/images/portal/presentation.jpg',
        },
    },
    'OPPORTUNITY': {
        'Open': {
            value: 'Opportunity',
            color: themeOptions.colors.primary,
            order: 40,
        },
        'Converted': {
            value: 'Opportunity',
            color: themeOptions.colors.primary,
            order: 41,
        },
        'Closed': {
            value: 'Opportunity',
            color: themeOptions.colors.primary,
            order: 41,
        },
    },
};

export function getAvatarSrc(avatar) {
    if (!avatar) {
        return "";
    }

    const src = avatar.original || avatar.medium || avatar.small;
    if (!src) {
        return "";
    }
    if (src.startsWith('http') || src.startsWith('https')) {
        return src;
    }
    return src.startsWith('/') ? src : '/' + src;
}
