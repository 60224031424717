import React from 'react';

/**
 * @example <RawHTML>{"<p>Hello World!</p>"}</RawHTML>;
 *
 * @param {React.PropsWithChildren} props
 * @returns {React.ReactNode}
 */
const RawHTML = ({children, ...props }) => (
    <div {...props} dangerouslySetInnerHTML={{ __html: children }} />
);

export default RawHTML;
